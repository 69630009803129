@import "../../../variables.scss";

.TaskName {
    color: $primary;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;

    &:hover {
	font-weight: 600;
    }
}
