@import "../../variables.scss";

.ContractsFilters {
    //background-color: #fcfcfc;
    //background-color: #f1f1f1;
    //background-color: #f9f9f9;;
    //background-color: #fff;
    //padding: 0 80px 40px 80px;
    padding: 0 80px 0 80px;
    //padding-left: 40px;
    position: fixed;
    //top: $app-toolbar-height + 60;
    top: $app-toolbar-height + $actions-bar-height;
    z-index: 1000;
    //border-bottom: 1px solid #eee;
    height: 80px;
    background-color: white;
}
