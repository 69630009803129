@import "../../variables.scss";

.SurveyRecordPhotos {
    margin-top: 30px;
    width: 100%;


    .Gallery {
	flex-wrap: wrap;

	.GalleryImage {
	    width: 400px;
	    height: 300px;
	    background-color: $alabaster;
	    margin-right: 25px;
	    margin-bottom: 75px;

	    img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	    }
	}

	.GalleryImagePlaceholder {
	    width: 400px;
	    height: 300px;
	    border: 4px solid lighten($artichoke, 30%);
	    margin-right: 25px;
	    margin-bottom: 75px;
	    opacity: 1;
	}

	.DragActive {
	    opacity: 0.7;
	    border-style: dashed;
	}

	.AddPhoto {
	    font-size: 72px;
	    margin-bottom: 20px;
	}

	.DescriptionWrapper {
	    margin-bottom: 40px;
	    margin-right: 25px;
	    max-width: 400px;

	    .Description {
		margin-bottom: 5px;
		line-height: 1.4em;
		outline-color: pink;

		&:focus {
		    background-color: #fff;
		}
	    }
	}

	.IsEditable {
	    &:hover {
		background-color: #f4f4f4;
	    }
	}
    }
}
