#CreateBadges {
    padding: 10px 20px;
    min-height: 300px;
    max-height: calc(100vh - 30%);

    h3 {
	margin-bottom: 15px;
    }

    button {
	margin-right: 10px;
    }

}
