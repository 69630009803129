.Main {
    margin-top: 40px;
    padding: 20px;

    .Roles {
	margin-top: 30px;
	margin-bottom: 30px;

	.Role {
	    margin-left: 20px;
	    margin-block-end: 0;
	    margin-block-start: 0;
	    font-size: 16px;
	}
    }
}
