.PageNavigator {
    position: fixed;
    font-size: 24px;
    color: #ffffff;
    top: 25px;
    left: 47%;

    svg {
	fill: #ffffff;
	font-size: 48px !important;
    }
}
