.Wrapper {
    max-width: 500px;
    padding: 40px;

    .Content {

	p {
	    margin-bottom: 20px;

	    &:last-of-type {
		margin-bottom: 0;
	    }
	}
    }
}
