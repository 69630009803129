#AuthView {
    background-image: url("../../assets/imgs/jason.webp");
    height: 100vh;
    background-size: cover;

    .Logo {
	width: 350px;
	margin-right: 200px;
    }

    .Main {
	width: 650px;
	height: 450px;
	padding: 50px;
	box-sizing: border-box;
    }
}
