@import "../../variables.scss";

.ContractTable {

    tr {

	td {
	    max-width: 300px;

	        span {
		    color: $artichoke;
		    cursor: pointer;
		    font-weight: bold;
		}
	}

	td:first-child {
	    min-width: 140px;
	}
    }
}
