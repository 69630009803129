.InterventionForm {

    .GeneralSection {
	margin-right: 25px;
    }

    .WeekBoundaries {
	margin-top: 20px;
    }
}


.LocationSection {
    margin-left: 25px;

    div {
	margin-bottom: 10px;
    }
}
