@import "../../variables.scss";

.ContractDocumentsFilters {
    position: fixed;
    top: $app-toolbar-height + $actions-bar-height;
    height: 80px;
    padding: 0 $datalist-lateral-padding 0 $datalist-lateral-padding;
    z-index: 1000;
    background-color: #ffffff;
}
