@import "../../variables.scss";

// .SideMenuHeader {
//     background-color: $artichoke;
//     color: white !important;
//     padding: 20px;

//     img {
// 	max-height: 50px;
// 	margin-left: 40px;
//     }

//     svg {
// 	color: white;
//     }
// }

.Sidemenu {
    width: 275px;
    height: 100%;
    background-color: #fafafa;
    padding: 120px 20px 0 20px;

    .Buttons {

	> a {
	    margin: 0 0 15px 15px;
	    text-decoration: none;
	    color: #212121;
	    font-weight: 500;
	    font-size: 14px;
	    opacity: 0.8;
	    vertical-align: middle;

	    &:hover {
		opacity: 1;
	    }

	    svg {
		margin-right: 10px;
		color: #737373;
	    }
	}

	.Disabled {
	    opacity: 0.5;

	    &:hover {
		opacity: 0.5;
	    }
	}
    }
}
