.Wrapper {
    padding: 30px;

    .Table {
	td {
	    border-bottom: none;
	    padding: 12px 0 12px 5px;
	}
    }
}

.Section {
    margin: 20px 0 0 15px;
}
