.NoUserFound {

    .NotFound {
	margin: 30px 0 40px 0;
    }

    .Create {
	margin-bottom: 30px;
    }
}

.CreateUserForm {
    max-width: 90%;

    p:last-of-type {
    	margin-top: 30px;
	margin-bottom: 30px;
    }

    Button[type="submit"] {
	margin-top: 20px;
    }
}

.UserEditorUpdate {
    h5 {
	margin-block-end: 0;
    }

    .Role {
	margin-left: 20px;
    }

    form {
	margin-top: 50px;
    }
}
