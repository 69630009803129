@import "../../mixins.scss";

.Wrapper {
    padding: 20px;

    .Instructions {
	margin-bottom: 40px;
    }

    .Name {
	@include Truncate()
    }

}
