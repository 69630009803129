@import "../../variables.scss";

.State {
    text-transform: capitalize;
    background-color: $artichoke;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    padding: 5px;
}
