
.Wrapper {
    height: 100vh;

    .Welcome {
	padding-bottom: 100px;

	.WelcomeActions {
	    width: 100%;
	    max-width: 1300px;
	    margin-top: 60px;
	    background-color: #D2D6A8;
	    padding: 50px;
	    border-radius: 8px;
	    color: #ffffff;

	    .ChooseYourOption {
		font-size: 30px;
		margin-bottom: 50px;
	    }

	    svg {
		fill: #ffffff;
		font-size: 48px;
		margin-bottom: 20px;
	    }


	    .WelcomeAction {
		max-width: 180px;
		cursor: pointer;

	    }
	}
    }

}
