// colors (nomes cores em: color-name.com)

$alabaster: #ebece3;
$artichoke: #a09d75;
$american-silver: #d2d2ce;
$primary: $artichoke;
$detail-view-background: #F8F8F8;
$option-background-color: #f5f5f5;
// dimensions

$app-toolbar-height: 85px;
$actions-bar-height: 140px;

// datalist view

$datalist-actions-bar-height: 140px;
$datalist-margin-top: 320px;
$datalist-lateral-padding: 80px;
