@import "../../variables.scss";

.Wrapper {
    padding-top: $app-toolbar-height + $actions-bar-height;
    background-color: $detail-view-background;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    .Content {
	padding: 0 $datalist-lateral-padding 0 $datalist-lateral-padding;
    }

    h3 {
	margin-top: 40px;
    }
}

.Section {
    padding-top: 0 !important;
    margin-right: 25px;

    div {
	margin-bottom: 20px;
    }
}

.ContractRoadsObservations {
    margin-top: 20px;
}
