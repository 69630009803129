
// existe um estilo similar no ContractStateRender

.StateTiny {
    padding: 2px 4px;
}

.StateSmall {
    padding: 3px 6px;
}

.StateMedium {
    padding: 5px 10px;
}
