.SurveyRecordForm {

    .GeneralSection {
	margin-right: 25px;

	div {
	    margin-bottom: 10px;
	}
    }
}


.LocationSection {
    margin-left: 25px;

    div {
	margin-bottom: 10px;
    }
}
