@import "../../variables.scss";

.Wrapper {
    padding-top: $app-toolbar-height + $actions-bar-height;
    background-color: $detail-view-background;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    .Content {
	padding: 0 $datalist-lateral-padding 0 $datalist-lateral-padding;
    }

    h3 {
	margin-top: 40px;
    }

}
