#SetPasswordForm {
    height: 100%;

    h4 {
	margin-bottom: 40px;
    }

    h5 {
	margin-bottom: 50px;
    }

    .SubmitButton {
     	margin-top: 25px;
     	float: right;
    }

    .ErrorMessage {
	color: #ff0000;
	margin-bottom: 20px;
	font-size: 14px;
    }

}
