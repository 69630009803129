@import "../../variables.scss";

.Wrapper {
    background-color: $detail-view-background;
    padding-top: $app-toolbar-height + $actions-bar-height;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    .Main {
	padding: 0 $datalist-lateral-padding 0 $datalist-lateral-padding;
    }

    .Toolbar {
	position: fixed;
	top: $app-toolbar-height;
	background-color: #ddd;
	width: 100%;
	height: 60px;
    }

    h4 {
	margin-top: 40px;
    }

    .Observations {
	margin-top: 25px;
    }

    .Issued {

    }
}

.Section {
    margin-right: 25px;

    div {
	margin-bottom: 10px;
    }
}
