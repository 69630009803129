.TaskFormWrapper {
    padding: 30px;

    .Table {
	td {
	    border-bottom: none;
	}
    }
}

.Section {
    margin: 20px 0 0 15px;
}
