.LoginForm {
    height: 100%;

    svg {
	margin-right: 10px;
    }

    a:link, a:visited {
	text-decoration: none;
	color: inherit;
    }

    .ErrorMessage {
	color: #ff0000;
	margin-bottom: 20px;
	font-size: 14px;
    }

    .Field {
	margin-bottom: 25px;
    }

}
