.Wrapper {

    .NoImports {
	padding: 20px;
	width: 350px;
    }

    table {
	p {
	    margin-block-start: 0;
	    margin-block-end: 0;

	    &:nth-child(2) {
		margin-top: 10px;

		b:nth-of-type(2) {
		    margin-left: 10px;
		}
	    }
	}
    }

}
