@import "../../variables.scss";

.TasksLists {
    margin-top: $datalist-margin-top;
    padding: 0 $datalist-lateral-padding 0 $datalist-lateral-padding;
}

.InterventionTasks {
    width: calc(100vw - ($datalist-lateral-padding * 2));
    padding-bottom: $datalist-lateral-padding;
}

.Overlaps {
    background-color: #ffeeee
}
