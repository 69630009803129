.Feedback {

    h4 {
	margin-bottom: 50px;
    }

    h5 {
	margin-bottom: 25px;
    }
}

#ResetPasswordForm {
    height: 100%;

    h4 {
	margin-bottom: 40px;
    }

    h5 {
	margin-bottom: 50px;
    }

    button {
	margin-top: 25px;
	float: right;
    }
}
