@import "../../variables.scss";

.Wrapper {
    background-color: $detail-view-background;
    padding-top: $app-toolbar-height + $actions-bar-height;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    .Main {
	padding: 50px;
    }

    h3 {
	margin-top: 40px;
    }

}

.Section {
    margin-right: 25px;

    div {
	margin-bottom: 10px;
    }
}
