.NoAccreditation {
    margin-top: 10px;
}

.AccreditationFound {
    margin-top: 10px;

    p {
	margin-bottom: 5px;
	margin-left: 10px;

	&:first-of-type {
	    margin-left: 0;
	    margin-bottom: 15px;
	}
    }
}
