@import "../../variables.scss";

#Badge {
    background-color: $alabaster;
    margin-bottom: 20px;
    padding: 10px 15px 0 15px;
    border-radius: 5px;

    .Content {
	padding: 0 10px 0px 10px;

	.Title {
	    color: $artichoke;
	    font-weight: 600;
	    text-transform: uppercase;
	}

	.SecondLine {
	    color: #4a4a4a;
	    opacity: 0.7;
	    font-weight: 500;
	}

    }
}
