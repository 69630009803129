.SurveyRecordVerification {

    margin: 20px 0;
    padding: 10px 0;

    &:hover {
	// background-color: #f4f4f4;
    }

    &:hover Button {
	visibility: visible;
    }

    Button {
	visibility: hidden;
    }
}
