.Base {
    font-size: 18px;
}

.Loading {
    color: gray;
}

.Ok {
    color: teal;

}
