.Wrapper {
    padding: 20px;

    .Category {
	margin-left: 15px;
    }

    table {

	tr {
	    td {
		white-space: nowrap;
	    }
	}
    }
}
