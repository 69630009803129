@import "../../variables.scss";

.ActionsBarList {
    position: fixed;
    top: $app-toolbar-height;
    width: 100%;
    height: $actions-bar-height;
    box-sizing: border-box;
    padding: 0 80px 0 80px;
    z-index: 999;
    background-color: #ffffff;
}

.ActionsBarDetail {
    background-color: #f1f1f1;
    box-sizing: border-box;
    height: 60px;
    padding: 0 30px 0 20px;
    position: fixed;
    top: 85px;
    width: 100%;
    z-index: 999;
}
