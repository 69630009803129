@import "../../variables.scss";

.AppToolbar{
    a {
	color: #fff;
    }
    height: $app-toolbar-height;

    .ConcessionName {
	font-size: 18px;
	font-weight: bold;
	letter-spacing: 1.2px;
    }

    .Profile {
	margin-left: 10px;
    }
}

.Logo {
    height: 52px;
}
