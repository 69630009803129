@import "../../variables.scss";

a.Link {
    // color: #9a9a9a;
    color: $primary;
    text-decoration: none;
    font-weight: bold;

    &:hover {
	font-weight: 600;
    }
}

.Allowed {
    fill: green;
}

.Expired, .NotAllowed {
    color: red;
}

.Category {

    svg {
	font-size: 18px;
	margin-left: 8px;
	color: #4a4a4a;
    }
}


.SubcategoryName {
     cursor: pointer;
     font-weight: 600;
     color: #4a4a4a;
     text-decoration: underline;
     text-decoration-style: dashed;
     text-decoration-color: #aaa;
}
