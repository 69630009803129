@import "../../variables.scss";

.Wrapper {
    padding-top: $app-toolbar-height + $actions-bar-height;
    background-color: $detail-view-background;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    h3 {
	margin-top: 30px;
	padding-left: 100px;
    }

}
