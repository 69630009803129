@import "../../variables.scss";

// #Settings {
//     padding-top: $app-toolbar-height;
//     background-color: $detail-view-background;
//     min-height: calc(100vh - $app-toolbar-height);

//     #SettingsEntities {
// 	//background-color: red;
//     }


//     #SettingsRecords {
// 	// background-color: green;
//     }


//     #SettingsEditor{
// 	// background-color: blue;
//     }

//     .Selected {
// 	font-weight: bold;
//     }

//     li {
// 	cursor: pointer;
//     }
// }


@import "../../variables.scss";

.Wrapper {
    background-color: $detail-view-background;
    padding-top: $app-toolbar-height + $actions-bar-height;
    min-height: calc(100vh - $app-toolbar-height - $actions-bar-height);

    .Main {
	padding: 50px;
    }


    .Toolbar {
	position: fixed;
	top: $app-toolbar-height;
	background-color: #ddd;
	width: 100%;
	height: 60px;
    }

    h3 {
	margin-top: 40px;
    }

    .Observations {
	margin-top: 25px;
    }

    .Issued {

    }
}

.Section {
    margin-right: 25px;

    div {
	margin-bottom: 10px;
    }
}

.SettingsEntities, .SettingsRecords, .SettingsEditor {
    padding: 20px;

    h4 {
	margin-bottom: 60px;
    }

    h5 {
	margin: 20px 0 20px 0;
	cursor: pointer;

	&:hover {
	    opacity: 0.8;
	}
    }

    .Actions {

	Button {
    	    margin-top: 35px;
    	    margin-left: 20px;
	}
    }
}

.Selected {
    color: $primary !important;
}
